<template>

    <div class="headerr">


        <img src="../assets/img/LOGO.png" style="width: 12%; max-width: 61px">

        <div class="menu">
            <div class="menu-item" style="    display: flex; justify-content: end;">
                <img src="../assets/img/cardapio.png" style="width: 40%;" class="">
                <div class="submenu">
                    <a @click="this.$router.push('/lista-convidado')">Check-in</a>
                    <a @click="deslogar()">Deslogar</a>
                </div>
            </div>
        </div>


    </div>

    <form @submit.prevent="handleSubmit" class="formu">

        <div style="margin-bottom: 15px; display: flex; flex-direction: column">
            <span style="text-align: start;">Nome: </span>
            <input style="width: 50%; height: 20px" type="text" v-model="nome">
        </div>

                <div style="margin-bottom: 15px; display: flex; flex-direction: column">

            <span style="text-align: start;">CPF: </span>
            <input style="width: 50%; height: 20px" type="text" v-model="cpf">
        </div>

                <div style="margin-bottom: 15px; display: flex; flex-direction: column">

            <span style="text-align: start;">Empresa: </span>
            <input style="width: 50%; height: 20px" type="text" v-model="empresa">
        </div>

                <div style="margin-bottom: 15px; display: flex; flex-direction: column">

            <span style="text-align: start;">Cargo: </span>
            <input style="width: 50%; height: 20px" type="text" v-model="cargo">
        </div>

                <div style="margin-bottom: 15px; display: flex; flex-direction: column">

            <span style="text-align: start;">E-mail: </span>
            <input style="width: 50%; height: 20px" type="text" v-model="email">
        </div>

        
                <div style="margin-bottom: 15px; display: flex; flex-direction: column">

            <span style="text-align: start;">Telefone: </span>
            <input style="width: 50%; height: 20px" type="text" v-model="telefone">
        </div>



        <select style="height: 26px; width: 52%;margin-bottom: 15px;" name="" id="" v-model="nivelhierarquicoSelecionado">
            <option v-for="nh in nivelHierarquico" :value="nh.valor">
                {{ nh.pergunta }}
            </option>
        </select>

        <select style="height: 26px; width: 52%;margin-bottom: 15px;" name="" id="" v-model="categoriaSelecionada">
            <option v-for="c in categoria" :value="c.valor">
                {{ c.pergunta }}
            </option>
        </select>

        <div style="display: flex; align-items: center; width: 50%; flex-direction: column;">
            <label style="text-align: end;" for="">Fazer check-in</label>
            <div class="toggler" >
                <input id="toggler-1" name="toggler-1" type="checkbox" value="1" v-model="checkin">
                <label for="toggler-1">
                    <svg class="toggler-on" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <polyline class="path check" points="100.2,40.2 51.5,88.8 29.8,67.5"></polyline>
                    </svg>
                    <svg class="toggler-off" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                        <line class="path line" x1="34.4" y1="34.4" x2="95.8" y2="95.8"></line>
                        <line class="path line" x1="95.8" y1="34.4" x2="34.4" y2="95.8"></line>
                    </svg>
                </label>
            </div>
        </div>

        <button @click="confirmEdit()" style="width: 50%; height: 40px; border-radius: 5px; margin-bottom: 30px; background-color: #101142;
  color: white; border: #101142; margin-top: -15px ">Confirmar</button>

    </form>



</template>


<script>
import api from '@/api';
import { toast } from 'vue3-toastify';


export default {
    name: 'FormularioEdit',
    data() {
        return {
            checkin: false,
            emailUsuarioLogado: null,
            nome: '',
            cpf: '',
            empresa: '',
            cargo: '',
            email: '',
            telefone: '',
            dadosEdit: {},
            ideveto: null,
            categoriaSelecionada: null,
            nivelhierarquicoSelecionado: null,
            questions: ['Nome', 'CPF', 'Empresa', 'Cargo', 'E-mail', 'Telefone'],
            nivelHierarquico: [
                { pergunta: 'Nível Hierárquico', valor: null },
                { pergunta: 'CEO', valor: 'CEO' },
                { pergunta: 'Presidente', valor: 'Presidente' },
                { pergunta: 'Vice-Presidente', valor: 'Vice-Presidente' },
                { pergunta: 'Diretor', valor: 'Diretor' },
                { pergunta: 'Gerente', valor: 'Gerente' },
                { pergunta: 'Coordenador', valor: 'Coordenador' },
                { pergunta: 'Supervisor', valor: 'Supervisor' },
                { pergunta: 'Analista', valor: 'Analista' },
                { pergunta: 'Outros', valor: 'Outros' }
            ],
            categoria: [
                { pergunta: 'Categoria', valor: null },
                { pergunta: 'Vip', valor: 'VIP' },
                { pergunta: 'Palestrante', valor: 'PALESTRANTE' },
                { pergunta: 'Premiado', valor: 'PREMIADO' },
                { pergunta: 'Patrocinador', valor: 'PATROCINADOR' },
                { pergunta: 'Outros', valor: 'OUTROS' },
            ]
        }
    },
    mounted(){
        this.dadosEdit = JSON.parse(localStorage.getItem('editagain')) || [];
        console.log('oit,',this.dadosEdit)
        this.ideveto = localStorage.getItem('eventoID')
        this.associarinformacoes()
        this.emailUsuarioLogado = localStorage.getItem('email');
    },
    methods:{
        associarinformacoes(){
            this.checkin = this.dadosEdit.isInscrito
            this.nome = this.dadosEdit.nome
            this.cpf = this.dadosEdit.cpf
            this.empresa = this.dadosEdit.empresa
            this.cargo = this.dadosEdit.cargo
            this.email = this.dadosEdit.email
            this.telefone = this.dadosEdit.celular
            this.categoriaSelecionada = this.dadosEdit.tipo || null
            this.nivelhierarquicoSelecionado = this.dadosEdit.nivelHierarquico || null
        },
        confirmEdit(){
            const data = {
                idParticipante: this.dadosEdit.idParticipante,
                idEvento: this.ideveto,
                emailUser: this.emailUsuarioLogado,
                nome: this.nome,
                email: this.email,
                celular: this.telefone,
                tipo: this.categoriaSelecionada,
                nivelHierarquico: this.nivelhierarquicoSelecionado,
                cargo: this.cargo,
                empresa: this.empresa,
                cpf: this.cpf
            }


            api
            .put('v1/Participante', data)
            .then(()=>{
                toast.success('Operação concluída com sucesso!');
                setTimeout(() => {
                    this.$router.push('mostrar-convidado')
                }, 2000);
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }

}


</script>



<style>
/* From Uiverse.io by mobinkakei */
.toggler {
    width: 72px;
    margin: 40px auto;

}

.toggler input {
    display: none;
}

/* From Uiverse.io by AbanoubMagdy1 */
.wave-group {
    position: relative;
}

.wave-group .input {
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 200px;
    border: none;
    border-bottom: 1px solid #515151;
    background: transparent;
}

.wave-group .input:focus {
    outline: none;
}

.wave-group .label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    display: flex;
}

.wave-group .label-char {
    transition: 0.2s ease all;
    transition-delay: calc(var(--index) * .05s);
}

.wave-group .input:focus~label .label-char,
.wave-group .input:valid~label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: #5264AE;
}

.wave-group .bar {
    position: relative;
    display: block;
    width: 200px;
}

.wave-group .bar:before,
.wave-group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #5264AE;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.wave-group .bar:before {
    left: 50%;
}

.wave-group .bar:after {
    right: 50%;
}

.wave-group .input:focus~.bar:before,
.wave-group .input:focus~.bar:after {
    width: 50%;
}



.toggler label {
    display: block;
    position: relative;
    width: 72px;
    height: 36px;
    border: 1px solid #d6d6d6;
    border-radius: 36px;
    background: #e4e8e8;
    cursor: pointer;
    margin-top: -25px
}

.toggler label::after {
    display: block;
    border-radius: 100%;
    background-color: #d7062a;
    content: '';
    animation-name: toggler-size;
    animation-duration: 0.15s;
    animation-timing-function: ease-out;
    animation-direction: forwards;
    animation-iteration-count: 1;
    animation-play-state: running;
}

.toggler label::after,
.toggler label .toggler-on,
.toggler label .toggler-off {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 26px;
    height: 26px;
    transform: translateY(-50%) translateX(-50%);
    transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

.toggler input:checked+label::after,
.toggler input:checked+label .toggler-on,
.toggler input:checked+label .toggler-off {
    left: 75%;
}

.toggler input:checked+label::after {
    background-color: #50ac5d;
    animation-name: toggler-size2;
}

.toggler .toggler-on,
.toggler .toggler-off {
    opacity: 1;
    z-index: 2;
}

.toggler input:checked+label .toggler-off,
.toggler input:not(:checked)+label .toggler-on {
    width: 0;
    height: 0;
    opacity: 0;
}

.toggler .path {
    fill: none;
    stroke: #fefefe;
    stroke-width: 7px;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

@keyframes toggler-size {

    0%,
    100% {
        width: 26px;
        height: 26px;
    }

    50% {
        width: 20px;
        height: 20px;
    }
}

@keyframes toggler-size2 {

    0%,
    100% {
        width: 26px;
        height: 26px;
    }

    50% {
        width: 20px;
        height: 20px;
    }
}

.formu {
    width: 35%;
    position: absolute;
    left: 40%;
    display: flex;
    flex-direction: column;
}
</style>